<template>
    <div class="pt-10">
      <center>
        <v-layout row wrap style="max-width:700px;text-align:left">
        <v-flex class="text-right pa-5 white">
          <v-btn @click="add" text large class="indigo--text"><v-icon class="mr-2">mdi-plus</v-icon><b>ADD Column</b></v-btn>
          <router-link :to="{ name: 'ViewChapter', params: { id: $route.params.id }}" target="_blank" style="text-decoration:none">
            <v-btn text large class="indigo--text text--darken-4"><v-icon class="mr-2">mdi-eye-outline</v-icon><b>PREVIEW</b></v-btn>
          </router-link>
           
        </v-flex>
            <v-flex v-for="item in pages" :key="item._id" style="min-height:100px"  class="mt-2 xs12 white">
              <div style="border-bottom:1px solid #E0E0E0" class="pa-2 text-right">
                <v-icon @click="openEditor(item,'TEXT')" small>mdi-format-text</v-icon>
                <v-icon @click="openEditor(item,'IMAGE')" class="ml-5" small>mdi-image-area</v-icon>
                <v-icon @click="openEditor(item,'VIDEO')" class="ml-5" small>mdi-video-outline</v-icon>
                <span style="border-left:1px solid #E0E0E0" class="ml-5 mr-5"></span>
                <v-icon @click="addBelow(item.position)" small>mdi-plus</v-icon>
                <span style="border-left:1px solid #E0E0E0" class="ml-5 mr-5"></span>
                <v-icon @click="move(item.position,item._id,'up')"  small>mdi-arrow-up</v-icon>
                <v-icon @click="move(item.position,item._id,'down')" small class="ml-5"> mdi-arrow-down</v-icon>
                <span style="border-left:1px solid #E0E0E0" class="ml-5 mr-5"></span>
                <v-icon @click="remove(item.position,item._id)" small>mdi-delete</v-icon>
              </div>
              <div v-html="item.content" class="pa-5" v-if="item.type == 'text'"></div>
              <div v-if="item.type == 'image'"><img :src="item.content" style="width:100%" /></div>
              <div v-if="item.type=='video'">
                <video width="100%" controls><source :src="item.content" type="video/mp4">Your browser does not support HTML video.</video>
              </div>
            </v-flex>
          </v-layout>
      </center>
         
        <v-dialog v-model="dialog_text" width="700">
          <v-card>
          <v-card-title class="text-h5 grey lighten-2">Edit</v-card-title>
          <v-card-text class="pt-5">
          <wysiwyg v-model="content" />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
             <v-btn text  @click="save('text')">SAVE</v-btn>
          </v-card-actions>
      </v-card>
     </v-dialog>

     <v-dialog v-model="dialog_image" width="700">
          <v-card>
          <v-card-title class="text-h5 grey lighten-2">Edit</v-card-title>
          <v-card-text class="pt-5">
          <uploader @uploadSuccess="uploadSuccess" v-if="!media_uploaded"></uploader>
          <img :src="content" style="width:100%" v-else/>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
             <v-btn text  @click="save('image')">SAVE</v-btn>
          </v-card-actions>
      </v-card>
     </v-dialog>

      <v-dialog v-model="dialog_video" width="700">
          <v-card>
          <v-card-title class="text-h5 grey lighten-2">Edit</v-card-title>
          <v-card-text class="pt-5">
          <uploader @uploadSuccess="uploadSuccess" v-if="!media_uploaded"></uploader>
          <video width="100%" controls v-else><source :src="content" type="video/mp4" >Your browser does not support HTML video.</video>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
             <v-btn text  @click="save('video')">SAVE</v-btn>
          </v-card-actions>
      </v-card>
     </v-dialog>
    </div>
</template>

<script>
import Axios from 'axios'
import {BASE_URL} from '../config'
import EditorOptions from '../components/EditorOptions.vue'
import Uploader from '../components/Uploader.vue'
export default {
    data(){
        return{
            content : "",
            selectedColumn : "",
            selectedType : "",
            tab : "",
            dialog_text : false,
            dialog_image : false,
            dialog_video : false,
            media_uploaded : false,
            pages : [],
            selectedColumn : "",
        }
    },
    components : {
      EditorOptions,Uploader
    },
    mounted(){
      this.getPages()
    },
    methods : {
       uploadSuccess(value) {
            this.media_uploaded = true
            this.content = value
        },
        async add(){
            let url = BASE_URL + "/pages/"+this.$route.params.id
            let payload = {}
            let {data} = await Axios.post(url,payload)
            this.getPages()
        },
        async addBelow(position){
            let url = BASE_URL + "/pages/add/below/"+this.$route.params.id
            let payload = {position : position}
            let {data} = await Axios.post(url,payload)
            this.getPages()
        },
         async remove(position,page){
            let url = BASE_URL + "/pages/"+position+"/"+this.$route.params.id+"/"+page
            let {data} = await Axios.delete(url)
            this.getPages()
        },
        async move(position,id,movement){
            if(position == 0 && movement == "up"){alert("CANT MOVE UP")}
            else if(position == this.pages.length -1 && movement == "down"){alert("CANT MOVE DOWN")}  
            else{
              let url = BASE_URL + "/pages/sort/"+id+"/"+this.$route.params.id+"?movement="+movement
              let payload = {position : position}
              let {data} = await Axios.put(url,payload)
              this.getPages()
            }
            
        },
        async getPages(){
          let url = BASE_URL + "/pages/"+this.$route.params.id
          let {data} = await Axios.get(url)
          this.pages = data
        },
        async openEditor(item,type){
          this.selectedType = item.type
          this.selectedColumn = item._id
          this.content = item.content
          if(type == "TEXT"){
            this.dialog_text = true
          }else if(type == "IMAGE"){
            this.dialog_image = true
          }else if(type == "VIDEO"){
            this.dialog_video = true
          }
          
        },
        async save(type){
          let url = BASE_URL + "/pages/"+this.selectedColumn
          let payload = {
            type : type,
            content : this.content
          }
          let result = await Axios.put(url,payload)
          this.content = ""
          this.media_uploaded = false
          this.dialog_video = false
          this.dialog_image = false
          this.dialog_text = false
          this.getPages()
        }
    }
}
</script>

<style>
    .indigo-border{
        border: 1px solid #3f51b5;
    }
    .grey-border{
        border: 1px solid #b0b0b0;
    }
</style>