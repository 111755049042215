<template>
    <div class="pl-10 pr-10">
        <v-layout row wrap>
            <v-flex xs12>
                <bread-crumbs :menu="'admin'" :page="'create admin'"></bread-crumbs>
            </v-flex>
            <v-flex xs12 class="white pa-10 mt-5">
                <v-layout row wrap>
                    <v-flex md6 sm12 xs12 class="pl-5 pr-5">
                        <p class="caption"><b>Name</b></p>
                        <v-text-field single-line outlined label="Name" v-model="name"></v-text-field>
                    </v-flex>
                    
                    <v-flex md6 sm6 xs12 class="pl-5 pr-5">
                        <p class="caption"><b>Email</b></p>
                        <v-text-field single-line outlined label="Email" v-model="email"></v-text-field>
                    </v-flex>
                    <v-flex md6 sm6 xs12 class="pl-5 pr-5">
                        <p class="caption"><b>Password</b></p>
                        <v-text-field single-line outlined label="Password" v-model="password"></v-text-field>
                    </v-flex>
                    <v-flex md6 sm6 xs12 class="pl-5 pr-5">
                        <p class="caption"><b>Role</b></p>
                        <v-select single-line outlined label="Role" v-model="role" :items='roles'></v-select>
                    </v-flex>
                    <v-flex md6 sm6 xs12 class="pl-5 pr-5">
                        <v-btn class="indigo white--text pl-10 pr-10" large @click="createAccount">SUBMIT</v-btn>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
import BreadCrumbs from '../components/BreadCrumbs.vue'
import Axios from 'axios'
import { BASE_URL } from '../config'

export default {
  components: { BreadCrumbs },
    data(){
        return{
            name : "",
            role : "",
            email : "",
            password : "",
            roles: ["SUPER ADMIN","CONTENT MANAGER"]
        }
    },
    methods : {
        async createAccount(){
            this.$store.dispatch("SET_PROGRESS",true)
            let payload = {
                name : this.name,
                role : this.role,
                email : this.email,
                password : this.password,
            }
            let url = BASE_URL + "/admin"
            let {data} = await Axios.post(url,payload)
            
            this.name = ""
            this.role = ""
            this.email = ""
            this.password = ""
            
            this.$toastr.s("Account Created", "A new admin-account has been added successfully");
            this.$store.dispatch("SET_PROGRESS",false)
        }
    }
}
</script>