<template>
    <v-layout row wrap class="white" style="height:100%">
        <v-flex md1></v-flex>
        <v-flex xs12 md5 class="" style="text-align:center">
            <v-layout row wrap class="pt-10">
                <v-flex md12 style="height:100px"></v-flex>
                <v-flex md7 class="pl-5 pr-5">
                    <p><b>LOGIN</b></p>
                </v-flex>
                <v-flex md7 class="pl-5 pr5">
                        <v-text-field single-line outlined rounded label="Email" v-model="email"></v-text-field>
                </v-flex>
                <v-flex md7 class="pl-5 pr5">
                        <v-text-field single-line outlined rounded label="Password" v-model="password"></v-text-field>
                </v-flex>
                 <v-flex md7 class="pl-5 pr5">
                        <v-btn class="purple white--text pl-10 pr-10" block large rounded @click="login">LOGIN</v-btn>
                        <v-progress-circular indeterminate color="purple" class="mt-10" v-if="loading"></v-progress-circular>

                </v-flex>
            </v-layout>
        </v-flex>
        <v-flex xs12 md6 style="background-image:url('https://images.unsplash.com/photo-1580894906475-403276d3942d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80'); 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;">
            
        </v-flex>
    </v-layout>
</template>

<script>
import Axios from 'axios'
import {BASE_URL} from '../config'
export default {
    data(){
        return{
            email : "",
            password : "",
            loading : false
        }
    },
    methods : {
        async login(){
            this.loading = true
            let url = BASE_URL + "/admin/login"
            let payload = {
                email : this.email,
                password : this.password
            }
            let {data} = await Axios.post(url,payload)
            this.loading = false
            if(data.success){
                delete data['success']
                this.$store.dispatch("SET_ADMIN",data)
                this.$router.push('/')
                
            }else{
                alert("login failure")
            }
            
        }
    }
}
</script>