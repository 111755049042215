<template>
    <div class="pl-10 pr-10 mt-10">
        <v-layout row wrap>
            <v-flex xs12>
                <bread-crumbs :menu="'courses'" :page="'all classes'"></bread-crumbs>
            </v-flex>
            <v-flex xs12 class="pa-10 white">
                <p class="caption"><b>ADD A COURSE</b></p>
                <v-text-field v-model="title" single-line outlined label="Class"></v-text-field>
                <v-btn class="pl-10 pr-10 indigo white--text" large @click="addClass">SUBMIT</v-btn>
            </v-flex>

            <v-flex xs12 class="mt-10">
                <v-simple-table>
                    <template v-slot:default>
                    <thead>
                        <tr>
                        <th class="text-left">
                            #
                        </th>
                        <th class="text-left">
                            Title
                        </th>
                        <th class="text-left">
                            Medium
                        </th>
                        <th class="text-left">
                            Actions
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                        v-for="(item,index) in courses"
                        :key="item.name"
                        >
                        <td>{{index + 1}}</td>
                        <td><b>{{ item.title }}</b></td>
                        <td><span v-if="item.medium">{{ item.medium }}</span><span v-else class="pink--text">__</span></td>
                        <td>
                            <v-icon class="indigo--text" @click="viewCourse(item._id)">mdi-magnify</v-icon>
                            <v-icon class="purple--text ml-5" @click="editCourse(item._id)">mdi-pencil</v-icon>
                            <v-icon class="indigo--text text--darken-4 ml-5" @click="viewLessons(item._id)">mdi-delete</v-icon>
                        </td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
            </v-flex>
        </v-layout>
    </div>
</template>


<script>
import Axios from 'axios'
import { BASE_URL } from '../config'
import BreadCrumbs from '../components/BreadCrumbs.vue'
export default {
  components: { BreadCrumbs },
    data(){
        return {
            courses : [],
            title : ''
        }
    },
    async mounted(){
       this.getClasses()
    },
    methods : {
        async getClasses(){
             this.$store.dispatch("SET_PROGRESS",true)
        let url = BASE_URL + "/classes"
        let {data} = await Axios.get(url)
        this.courses = data
        this.$store.dispatch("SET_PROGRESS",false)
        },
        async addClass(){
            this.$store.dispatch("SET_PROGRESS",true)
            let url = BASE_URL + "/classes"
            let payload = {
                title : this.title 
            }
            let {data} = await Axios.post(url,payload)
            this.$store.dispatch("SET_PROGRESS",false)
            this.$router.push({name : 'EditClass',params : {id : data._id}})
            this.getClasses()

        },
        viewCourse(id){
            this.$router.push({name : 'Subjects',params : {id : id}})
        },
        viewLessons(id){
            this.$router.push({name : 'Lessons',params : {course_id : id}})
        },
        editCourse(id){
            this.$router.push({name : 'EditClass',params : {id : id}})
        }
    }
}
</script>
