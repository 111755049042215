<template>
    <div class="pl-10 pr-10 mt-10">
        <v-layout row wrap>
            <v-flex xs12 md12>
                <bread-crumbs :page="'admin'" :menu="'view account'"></bread-crumbs>
            </v-flex>
            <v-flex xs12 md6>
                <v-simple-table>
                    <template v-slot:default>
                    <tbody>
                        <tr>
                            <td><b>Name</b></td>
                            <td>{{ account.name }}</td>
                        </tr>
                         <tr>
                            <td><b>Role</b></td>
                            <td><b>{{ account.role }}</b></td>
                        </tr>
                         <tr>
                            <td><b>Email</b></td>
                            <td>{{ account.email }}</td>
                        </tr>
                         <tr >
                            <td><b>Actions</b></td>
                            <td class="pt-10 pb-10"><v-btn rounded large class="red white--text pl-10 pr-10" @click="deleteAccount">delete</v-btn></td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
import Axios from 'axios'
import BreadCrumbs from '../components/BreadCrumbs.vue'
import { BASE_URL } from '../config'
export default {
  components: { BreadCrumbs },
    data(){
        return {
            account : {},
            subscriptions : []
        }
    },
    methods : {
        async deleteAccount(){
            this.$store.dispatch("SET_PROGRESS",true)
            let context = this
            this.$swal({
                title: "Delete this record?",
                text: "Are you sure? You won't be able to revert this!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f44336",
                confirmButtonText: "Yes, Delete it!"
            }).then(async (result) => {
                if (result.value) {
                    let url = BASE_URL + "/admin/"+this.$route.params.id
                    let {data} = await Axios.delete(url)
                    context.$toastr.s("Record Deleted", "The record was deleted successfully");
                    context.$router.push('/view-admins')    
                }
            });
            this.$store.dispatch("SET_PROGRESS",false)
        }
    },
    async mounted(){
        this.$store.dispatch("SET_PROGRESS",true)
        {   let url = BASE_URL + "/admin/"+this.$route.params.id
            let {data} = await Axios.get(url)
            this.account = data
        }
        this.$store.dispatch("SET_PROGRESS",false)
    }
}
</script>

