<template>
  <v-app>
    <app-tool-bar v-if="ADMIN.admin!=undefined"></app-tool-bar>
    <v-main class="app">
      
      <router-view v-if="ADMIN.admin!=undefined"/>
      <login v-else></login>
    </v-main>
  </v-app>
</template>

<script>
import ToolBar from '@/components/ToolBar.vue';
import {mapGetters} from 'vuex'
import Login from './views/Login.vue';
export default {
  name: 'App',
  computed : {
    ...mapGetters(['ADMIN'])
  },
  components: {
    AppToolBar : ToolBar,
    Login : Login
  },
  
   
};
</script>

<style>
 .app{
   background-color: #eceff1;
   font-family: 'Montserrat', sans-serif;
 }
 .caps{
   text-transform: uppercase;
 }
</style>
