import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Editor from '../views/Editor.vue'
import CreateAdmin from '../views/CreateAdmin'
import ViewAdmins from '../views/ViewAdmins'
import ViewAdminAccount from '../views/ViewAdminAccount'
import Classes from '../views/Classes'
import EditClass from '../views/EditClass'
import Subjects from '../views/Subjects'
import Chapters from '../views/Chapters'
import ViewChapter from '../views/ViewChapter'

Vue.use(VueRouter)

const routes = [
  {
    path : '/create-admin',
    name : "CreateAdmin",
    component : CreateAdmin
  },
  {
    path : '/view-admins',
    name : "ViewAdmins",
    component : ViewAdmins
  },
  {
    path : '/view-admin-acount/:id',
    name : 'ViewAdminAccount',
    component : ViewAdminAccount
  },
  {
    path : '/',
    name : 'Classes',
    component : Classes
  },
  {
    path : '/edit-class',
    name : 'EditClass',
    component : EditClass
  },
  {
    path : '/subjects/:id',
    name : 'Subjects',
    component : Subjects
  },
  {
    path : '/chapters/:id',
    name : 'Chapters',
    component : Chapters
  },
  {
    path: '/editor/:id',
    name: 'Editor',
    component: Editor
  },
  {
    path: '/view-chapter/:id',
    name: 'ViewChapter',
    component: ViewChapter
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
