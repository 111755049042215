<template>
  <div class="">
    <v-navigation-drawer
        v-model="drawer"
        :clipped="$vuetify.breakpoint.lgAndUp"
        app
      >
        <v-list dense>
          <template v-for="item in items">
            <v-row
              v-if="item.heading"
              :key="item.heading"
              align="center"
            >
              <v-col cols="6">
                <v-subheader v-if="item.heading">
                 {{ item.heading }}
                </v-subheader>
              </v-col>
              <v-col
                cols="6"
                class="text-center"
              >
                <a
                  href="#!"
                  class="body-2 black--text"
                >EDIT</a>
              </v-col>
            </v-row>
            <v-list-group
              v-else-if="item.children"
              :key="item.text"
              v-model="item.model"
              :append-icon="item.append_icon"
              :prepend-icon="item.model ? item.icon : item['icon-alt']"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>
                    <b>{{ item.text }}</b>
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                v-for="(child, i) in item.children"
                :key="i"
                class="grey lighten-4"
                link
                @click="selectItem(child.text)"
              >
                <v-list-item-action v-if="child.icon">
                  <v-icon>{{ child.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ child.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <v-list-item
              v-else
              :key="item.text"
              link
              @click="selectItem(item.text)"
            >
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  <b>{{ item.text }}</b>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-navigation-drawer>
  
        <v-app-bar
        :clipped-left="$vuetify.breakpoint.lgAndUp"
        app
        color="white"
        extended extension-height="5"
        light
      >
        <v-icon @click.stop="drawer = !drawer">mdi-menu</v-icon>
        <v-toolbar-title
          style="width: 300px"
          class="ml-0 pl-4"
          
        >
          <span class="hidden-sm-and-down"><img src="../assets/clapp-logo.jpeg" style="width:100px" class="mt-3" @click="loadHome"/></span>
        </v-toolbar-title>
      
        <v-spacer></v-spacer>
        <v-btn icon>
          <v-icon>mdi-apps</v-icon>
        </v-btn>
        
        <v-btn
          icon
          large
        >
          <v-avatar
            size="32px"
            item
          >
            <v-img
              src="../assets/avatar.jpg"
              alt="Vuetify"
              @click="loadAbout"
            ></v-img></v-avatar>
        </v-btn>
         <v-progress-linear :indeterminate="true" v-if="PROGRESS" slot="extension" class="ma-0" color="purple darken-2" background-color="purple lighten-4" height="5"></v-progress-linear> 
      </v-app-bar>
  



  </div>


</template>

<script>
import {mapGetters} from 'vuex'
  export default {
    computed : {
      ...mapGetters(['PROGRESS'])
    },
    data: () => ({
      dialog: false,
      drawer: false,
      items: [
        {
          icon: 'mdi-home-outline',
          'icon-alt': 'mdi-home-outline',
          'append_icon' : 'mdi-menu-down',
          text: 'Home',
          model: false,
        },
        {
          icon: 'mdi-account-multiple-outline',
          'icon-alt': 'mdi-account-multiple-outline',
          'append_icon' : 'arrow_drop_down',
          text: 'Users',
          model: false,
        },
        {
          icon: 'mdi-school',
          'icon-alt': 'mdi-school',
          'append_icon' : 'arrow_drop_down',
          text: 'Schools',
          model: false,
        },
        {
          icon: 'mdi-account-outline',
          'icon-alt': 'mdi-account-outline',
          text: 'Admin',
          append_icon : 'arrow_drop_down',
          model: false,
        },
        {
          icon: 'mdi-cog-outline',
          'icon-alt': 'mdi-cog-outline',
          text: 'Settings',
          model: false,
        },
        {
          icon: 'mdi-power',
          'icon-alt': 'mdi-power',
          text: 'Logout',
          model: false,
        },
      ]
    }),
    methods : {
      loadAbout(){
        this.$router.push('/about')
      },
      loadHome(){
        this.$router.push('/')
      },
      selectItem(item){
        
        if(item == "Courses"){
            this.$router.push({name : 'Courses'})  
        }
        if(item == "New Registration"){
            this.$router.push('/create-account')
        }
        if(item == "View Registrations"){
            this.$router.push('/view-registrations')
        }
        if(item == "Create Admin"){
          this.$router.push('/create-admin')
        }
         if(item == "View Admins"){
          this.$router.push('/view-admins')
        }
        if(item == "Settings"){
          this.$toastr.e("You do not have the required permissin to access this menu.","Permission denied")
        }
        if(item == "Subscriptions"){
          this.$router.push('/subscriptions')
        }
        
      },
      clickChild(child){

        if(child.text == "New Registration"){
            this.$router.push('/create-account')
        }
        if(child.text == "View Registrations"){
            this.$router.push('/view-registrations')
        }
        if(child.text == "Filter By Date"){
            this.$router.push('/orders/date-range')
        }
        if(child.text == "Search"){
            this.$router.push('/')
        }
      }
    },
    props: {
      source: String
    },

  }
</script>