<template>
    <div class="pl-10 pr-10 mt-10">
        <v-layout row wrap>
            <v-flex xs12>
                <bread-crumbs :menu="'classes'" :page="'chapters'"></bread-crumbs>
            </v-flex>
            <v-flex xs12 class="pa-10 white">
                <p class="caption"><b>ADD A CHAPTER</b></p>
                <v-text-field v-model="title" single-line outlined label="Chapter"></v-text-field>
                <v-btn class="pl-10 pr-10 indigo white--text" large @click="addClass">SUBMIT</v-btn>
            </v-flex>

            <v-flex xs12 class="mt-10">
                <v-simple-table>
                    <template v-slot:default>
                    <thead>
                        <tr>
                        <th class="text-left">
                            #
                        </th>
                        <th class="text-left">
                            Title
                        </th>
                        <th class="text-left">
                            Actions
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                        v-for="(item,index) in subjects"
                        :key="item.name"
                        >
                        <td>{{index + 1}}</td>
                        <td><b>{{ item.title }}</b></td>
                        <td>
                            <v-icon class="indigo--text" @click="viewPages(item._id)">mdi-magnify</v-icon>
                            <v-icon class="purple--text ml-5" @click="editPages(item._id)">mdi-pencil</v-icon>
                            <v-icon class="indigo--text text--darken-4 ml-5" @click="viewLessons(item._id)">mdi-delete</v-icon>
                        </td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
            </v-flex>
        </v-layout>
         <v-dialog v-model="dialog" width="700">
          <v-card>
          <v-card-title class="text-h5 grey lighten-2">Edit</v-card-title>
          <v-card-text class="pt-5">
          <v-text-field v-model="chapter" single-line outlined></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
             <v-btn text  @click="save()">SAVE</v-btn>
          </v-card-actions>
      </v-card>
     </v-dialog>
    </div>
</template>


<script>
import Axios from 'axios'
import { BASE_URL } from '../config'
import BreadCrumbs from '../components/BreadCrumbs.vue'
export default {
  components: { BreadCrumbs },
    data(){
        return {
            subjects : [],
            title : '',
            chapter : '',
            chapter_id : '',
            dialog : false
        }
    },
    async mounted(){
       this.getChapters()
    },
    methods : {
        edit(chapter,id){
            this.chapter = chapter
            this.chapter_id = id
            this.dialog = true
        },
        async getChapters(){
             this.$store.dispatch("SET_PROGRESS",true)
            let url = BASE_URL + "/chapters/"+this.$route.params.id
            let {data} = await Axios.get(url)
            this.subjects = data
            this.$store.dispatch("SET_PROGRESS",false)
        },
        async save(){
            this.$store.dispatch("SET_PROGRESS",true)
            let url = BASE_URL + "/chapters/"+this.chapter_id
            let payload = {
                title : this.chapter
            }
            let {data} = await Axios.put(url,payload)
            this.$store.dispatch("SET_PROGRESS",false)
            this.dialog = false
            this.getChapters()
        },
        async addClass(){
            this.$store.dispatch("SET_PROGRESS",true)
            let url = BASE_URL + "/chapters/"+this.$route.params.id
            let payload = {
                title : this.title 
            }
            let {data} = await Axios.post(url,payload)
            this.$store.dispatch("SET_PROGRESS",false)
            this.getChapters()
            // this.$router.push({name : 'EditClass',params : {id : data._id}})

        },
        viewPages(id){
            this.$router.push({name : 'ViewChapter',params : {id : id}})
        },
        viewLessons(id){
            this.$router.push({name : 'Lessons',params : {course_id : id}})
        },
        editPages(id){
            this.$router.push({name : 'Editor',params : {id : id}})
        }
    }
}
</script>
