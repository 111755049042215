<template>
    <div style="border:1px solid #E0E0E0">
        <div>
            <v-btn @click="addColumn" block large class="indigo white--text">ADD Column</v-btn>
        </div>
    </div>
</template>

<script>
import Axios from 'axios'

export default {
    data(){
        return{

        }
    },
    async mounted(){

    },
    methods : {
       
    }

}
</script>