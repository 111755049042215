<template>
    <div class="pl-10 pr-10 mt-10">
        <v-layout row wrap>
            <v-flex xs12>
                <bread-crumbs :menu="'courses'" :page="'edit course'"></bread-crumbs>
            </v-flex>
            <v-flex xs12 md8 class="white pa-10 mt-10">
                <v-layout row wrap >
                    <v-flex md6 class="pl-5 pr5">
                        <p class="caption"><b>Title</b></p>
                        <v-text-field single-line outlined label="Title" v-model="title"></v-text-field>
                    </v-flex>
                    <v-flex md6 class="pl-5 pr5">
                        <p class="caption"><b>Medium</b></p>
                        <v-text-field single-line outlined label="Medium" v-model="medium"></v-text-field>
                    </v-flex>
                    <v-flex xs12 class="pl-5 pr-5 mt-5">
                        <v-btn class="pl-10 pr-10 indigo white--text" @click="saveCourse" large>SUBMIT</v-btn>
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex md4 class="mt-10 px-5">
                <p class="pa-3 white caption"><b>FEATURED IMAGE</b></p>
                <uploader @uploadSuccess="uploadSuccess"></uploader>
                <br>
                <img :src="image_url" style="width:100%"/>
                <v-btn large block class="blue white--text" @click="saveThumbnail" v-if="image_uploaded">SAVE</v-btn>
            </v-flex>
        </v-layout>
        
        
    </div>
</template>

<script>
import Axios from 'axios'
import BreadCrumbs from '../components/BreadCrumbs.vue'
import { BASE_URL } from '../config'
import Uploader from '../components/Uploader.vue'
export default {
  components: { BreadCrumbs,Uploader },
    data(){
        return{
            title : "",
            code: "",
            thumbnail : "",
            image_url : "",
            image_uploaded : false
        }
        
    },
    async mounted(){
        {   this.$store.dispatch("SET_PROGRESS",true)
            let url = BASE_URL + "/classes/"+this.$route.params.id
            let {data} = await Axios.get(url)
            this.title = data.title
            this.medium = data.medium
            this.image_url = data.thumbnail
            this.$store.dispatch("SET_PROGRESS",false)
        }
    },
    methods : {
        uploadSuccess(value) {
            this.image_uploaded = true
            this.image_url = value
        },
        async saveCourse(){
            this.$store.dispatch("SET_PROGRESS",true)
            let url = BASE_URL + "/classes/"+this.$route.params.id
            let payload = {
                title : this.title,
                medium : this.medium,
            }
            let {data} = await Axios.put(url,payload)
            this.$store.dispatch("SET_PROGRESS",false)
        },
        async saveThumbnail(){
            this.$store.dispatch("SET_PROGRESS",true)
            let url = BASE_URL + "/classes/thumbnail/"+this.$route.params.id
            let payload = {
                thumbnail : this.image_url
            }
            let {data} = await Axios.put(url,payload)
            this.image_uploaded = false
            this.$store.dispatch("SET_PROGRESS",false)
        }
    }
}
</script>