<template>
    <div class="pl-10 pr-10 mt-10">
        <v-layout row wrap>
            <v-flex xs12>
                <bread-crumbs :menu="'admin'" :page="'admin accounts'"></bread-crumbs>
            </v-flex>
            <v-flex xs12>
                <v-simple-table>
                    <template v-slot:default>
                    <thead>
                        <tr>
                        <th class="text-left">
                            #
                        </th>
                        <th class="text-left">
                            Name
                        </th>
                        <th class="text-left">
                            Role
                        </th>
                         <th class="text-left">
                            Email
                        </th>
                         <th class="text-left">
                            Actions
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                        v-for="(item,index) in users"
                        :key="item._id"
                        >
                        <td>{{index+1}}</td>
                        <td>{{ item.name }}</td>
                        <td><b>{{ item.role }}</b></td>
                        <td class="grey--text">{{item.email}}</td>
                        <td><v-icon @click="viewAccount(item._id)" class="purple--text">search</v-icon></td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
import Axios from 'axios'
import { BASE_URL } from '../config'
import BreadCrumbs from '../components/BreadCrumbs.vue'
export default {
  components: { BreadCrumbs },
    data(){
        return{
            users : []
        }
    },
    async mounted(){
        {   this.$store.dispatch("SET_PROGRESS",true)
            let url = BASE_URL + "/admin"
            let {data} = await Axios.get(url)
            this.users = data
            this.$store.dispatch("SET_PROGRESS",false)
        }
    },
    methods : {
        viewAccount(id){
            this.$router.push({name : 'ViewAdminAccount',params : {id : id}})
        }
    }
}
</script>